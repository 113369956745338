<template>
  <div :class="$style.root">
    <div v-for="type in Object.keys(values)" :key="type">
      <template v-if="type === 'BOOL'">
        <VRow dense>
          <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
            </template>
            <template v-else>
              <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
                <VRadio label="Да" :value="'1'" />
                <VRadio label="Нет" :value="'0'" />
              </VRadioGroup>
            </template>
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'TEXT'">
        <VRow dense>
          <VCol v-for="(field, index) in values['TEXT']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" />
            </template>
            <template v-else>
              <VTextarea
                  v-model="field.value"
                  outlined
                  dense
                  auto-grow
                  rows="1"
                  hideDetails="auto"
                  clearable
                  :append-icon="!field.value && values['TEXT'].length > 1 ? 'mdi-close': null"
                  @click:clear="onDelete(values['TEXT'], index)"
                  @click:append="onDelete(values['TEXT'], index)"
              />
            </template>
          </VCol>
          <VCol v-if="!readonly && multiple" cols="12">
            <div class="mt-2">
              <VBtn color="primary" @click="onPush(values['TEXT'])" small depressed>Добавить</VBtn>
            </div>
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'LIST'">
        <VRow dense>
          <VCol v-if="multiple" cols="12" md="6">
            <SelectMultiple v-model="values['LIST']" :options="options" :readonly="readonly" />
          </VCol>
          <template v-else>
            <VCol v-for="(field, index) in values['LIST']" cols="12" :key="field.type + index">
              <template v-if="field.readonly || readonly">
                <StaticField :value="field.value" :items="options" />
              </template>
              <template v-else>
                <VSelect v-model="field.value" :items="options" outlined dense hideDetails="auto" />
              </template>
            </VCol>
          </template>
        </VRow>
      </template>
      <template v-if="type === 'FILE'">
        <VRow dense>
          <VCol cols="12">
            <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" />
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'DATE'">
        <VRow dense>
          <VCol v-for="(field, index) in values['DATE']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" />
            </template>
            <template v-else>
              <DateInput v-model="field.value" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'EMAIL'">
        <VRow dense>
          <VCol v-for="(field, index) in values['EMAIL']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" />
            </template>
            <template v-else>
              <VTextField
                  v-model="field.value"
                  outlined
                  dense
                  hideDetails="auto"
                  :rules="rules.email"
              />
            </template>
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'PHONE'">
        <VRow dense>
          <VCol v-for="(field, index) in values['PHONE']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value | VMask('+7 (###) ### ## ##')" />
            </template>
            <template v-else>
              <VTextField
                  v-model="field.value"
                  v-mask="'+7 (###) ### ## ##'"
                  outlined
                  dense
                  hideDetails="auto"
                  :rules="rules.phone"
              />
            </template>
          </VCol>
        </VRow>
      </template>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/general/DateInput/DateInput';
import StaticField from '@/components/general/StaticField/StaticField';
import SelectMultiple from '@/components/general/SelectMultiple/SelectMultiple';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
import { keys, find, merge, head } from 'lodash-es';
export default {
  name: 'EmployeeDetailField',
  components: {
    DateInput,
    StaticField,
    SelectMultiple,
    FileFieldMultiple,
  },
  props: {
    label: { type: String },
    values: { type: Object },
    multiple: { type: Boolean },
    readonly: { type: Boolean },
    comments: { type: Object },
  },
  data() {
    return {
      rules: {
        phone: [
          (value) => /[+7] \([\d]{3}\) [\d]{3} [\d]{2} [\d]{2}/.test(value) || 'Укажите корректный телефон',
        ],
        email: [
          (value) => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value) || 'Укажите корректный email',
        ]
      }
    }
  },
  computed: {
    disabled() {
      return !!find(this.values, { id: '' });
    },
    nowISO() {
      return new Date().toISOString();
    },
    count() {
      return keys(this.values).length;
    }
  },
  methods: {
    onDelete: function(values, index) {
      if (values.length > 1) values.splice(index, 1);
    },
    onPush: function(values) {
      const stub = JSON.parse(JSON.stringify(head(values)));
      values.push(merge(stub, { id: '', date: '', value: '' }))
    },
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
