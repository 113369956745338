<template>
  <VExpansionPanel>
    <VExpansionPanelHeader class="title">
      <div class="d-flex align-center">
        <div class="title">{{ label }}</div>
        <div class="flex-grow-1 pl-4">
          <VTooltip v-if="commentsInCurrentSectionCount['errors']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="error">{{commentsInCurrentSectionCount['errors']}}</VChip>
            </template>
            <span>Замечания</span>
          </VTooltip>
          <VTooltip v-if="commentsInCurrentSectionCount['warnings']" right>
            <template v-slot:activator="{ on, attrs }">
              <VChip class="mr-2" v-bind="attrs" v-on="on" color="primary">{{commentsInCurrentSectionCount['warnings']}}</VChip>
            </template>
            <span>Исправления</span>
          </VTooltip>
        </div>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent eager>
      <div v-for="question in orderControl(questions)" :class="[$style.item, errorControl(commentControl(question.question), question.changed)]"  :key="question.code">
        <VRow dense>
          <VCol cols="12" md="3" class="pr-sm-4">
            <div :class="$style.title" v-html="question.label" />
            <div v-if="question.hint" :class="$style.hint" v-html="question.hint" />
          </VCol>
          <VCol cols="12" md="9">
            <VRow dense class="flex-nowrap">
              <VCol cols="auto" class="flex-grow-1 flex-shrink-1">
                <EmployeeDetailField v-bind="{...question}" :comments="comments" :readonly="readonly" />
              </VCol>
              <VCol cols="auto">
                <template v-if="isReviewer">
                  <VTooltip v-if="commentControl(question.question).length" left>
                    <template v-slot:activator="{ on, attrs }">
                      <VBtn v-bind="attrs" v-on="on" fab small depressed color="primary" class="mr-2" @click="toViewed(question.question)">
                        <VIcon small v-text="'mdi-check'"/>
                      </VBtn>
                    </template>
                    <span>Принять</span>
                  </VTooltip>
                </template>
                <VTooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <VBtn
                        fab
                        small
                        depressed
                        :color="isContragent && commentControl(question.question).length ? 'primary': 'light'"
                        v-bind="attrs"
                        v-on="on"
                        @click="toComments(question.code)"
                    >
                      <VIcon small v-text="'mdi-comment-multiple-outline'"/>
                    </VBtn>
                  </template>
                  <span>Замечания</span>
                </VTooltip>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </div>
    </VExpansionPanelContent>
  </VExpansionPanel>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {filter, find, get, pick, values, map, sortBy, reduce} from 'lodash-es';
import {ROLES} from '@/store/user/enums';
import EmployeeDetailField from '@/components/elkp/EmployeeDetailField/EmployeeDetailField';
export default {
  name: 'EmployeeDetailSection',
  components: {
    EmployeeDetailField,
  },
  props: {
    label: { type: String },
    employee: { type: String },
    questions: { type: Object },
    readonly: { type: Boolean },
    comments: { type: Object },
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    commentsInCurrentSection() {
      return pick(this.comments, map(this.questions, 'question'));
    },
    commentsInCurrentSectionCount() {
      return reduce(this.commentsInCurrentSection, (acc, comments, question) => {
        if (this.isContragent) {
          if (find(comments, { actual: true })) {
            acc['errors'] += 1;
            return acc;
          }
        }
        if (this.isReviewer) {
          if (find(comments, { draft: true })) {
            acc['errors'] += 1
            return acc;
          }
          if (find(comments, { actual: true })) {
            acc['warnings'] += 1;
            return acc;
          }
          if (get(this.questions, [question, 'changed'], false)) {
            acc['warnings'] += 1;
            return acc;
          }
        }
        return acc;
      }, { errors: 0, warnings: 0 })
    },
    isContragent() {
      return this.hasRole([ROLES.CONTRAGENT, ROLES.CONTRACT_HOLDER], 'elkp');
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'elkp');
    },
  },
  methods: {
    ...mapActions({
      fetchCommentList: 'elkp/fetchCommentList',
      viewedComments: 'elkp/viewedComments'
    }),
    orderControl(items) {
      return sortBy(values(items), 'order');
    },
    commentControl(question) {
      return filter(this.commentsInCurrentSection[question], ({ actual, draft }) => draft || actual)
    },
    errorControl(comments, changed) {
      if (this.isContragent && comments.length) return 'error lighten-4';
      if (this.isReviewer && find(comments, { draft: true})) return 'error lighten-4';
      if (this.isReviewer && (changed || find(comments, { actual: true}))) return 'orange lighten-4';
    },
    toComments(question) {
      this.$router.push({ name: 'elkp/EmployeeDetailView', params: { question }})
    },
    toViewed(question) {
      const comments = map(get(this.comments, question), 'id');
      this.viewedComments({ items: comments}).then((result) => {
        if (result) {
          const id = this.employee;
          this.fetchCommentList({ id });
        }
      });
    }
  }
}
</script>

<style module lang="scss">
.item {
  padding: 20px 24px;
  margin: 0 -24px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
  .title {
    font-weight: bold;
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
  .hint {
    font-style: italic;
    font-size: 13px;
  }
}
</style>
